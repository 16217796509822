<template>
    <div class="page">
        <div class="content">
            <div class="page-title flex justify-between bg-white">
                <h1 class="typography-h1 title bg-white">
                    Uživatelé
                </h1>
                <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg my-toggle" left-label :label="$t('label.show-archived')" />
            </div>

            <div class="wrapper">
                <div class="row">
                    <div class="col-4 q-pr-md discounts-wrapper">
                        <q-btn
                            v-if="!createMode|| active || viewMode"
                            @click="showCreateCard"
                            class="my-btn create-card"
                            style="width: 100%"
                            label="Přidat uživatele"
                            type="text"
                            color="black"
                            icon-right="svguse:/icons.svg#plus2px"
                        />
                        <CardUser class="skeleton" @remove="createMode=false" v-if="createMode && !active"/>
                        <div class="q-mb-md"></div>
                        <div
                            v-if="filteredUsers.length"
                            v-for="user in filteredUsers"
                            :key="user.id"
                            class="q-mb-md">
                            <CardUser
                                :active="active && (active.id === user.id)"
                                @click.native="setActive(user)"
                                :content="user"
                                @remove="del(user)"/>
                        </div>
                    </div>
                    <div class="col-8">
                        <div v-if="!filteredUsers.length && !createMode && !viewMode" class="row justify-center items-center"
                             style="height: 188px; width: 100%; border: 1px solid #BABABA">
                            <q-icon name="svguse:/icons.svg#drag-drop" size="48px"/>
                        </div>
                        <div v-if="filteredUsers.length && !createMode && !viewMode" class="empty">
                            <q-icon name="svguse:/icons.svg#empty" size="48px" class="block q-mb-md"/>
                            <span class="text-grey-6">{{$t('label.select-user')}}</span>
                        </div>
                        <CardUserCreate @close="closeEdit" @create="createUser" v-if="createMode && !viewMode && !active"/>
                        <CardUserCreate @close="closeEdit" :content="active" @create="updateUser" v-if="viewMode && active"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardUser from "../components/Card/CardUser/CardUser";
import CardUserCreate from "../components/Card/CardUser/CardUserCreate";

export default {
    components: {CardUserCreate, CardUser},
    meta:{
        title: 'Users',
    },

    data: () => ({
        createMode: false,
        active: null,
        viewMode: false,
        listArchived: false,
        errors:[],
    }),
    computed:{
        filteredUsers() {
            return (this.users || []).filter(u => !u.isSystemUser && (this.listArchived ? u.archived : !u.archived)).reverse();
        },
    },
    methods: {
        showCreateCard() {
            this.active = null
            this.createMode = false
            this.viewMode = false;
            this.active = null;
            setTimeout(() => {
                this.createMode = true
            }, 0)
        },
        setActive(item) {
            this.createMode = false
            this.viewMode = true;
            this.active = null
            setTimeout(() => {
                this.active = item
                this.viewMode = true
            }, 0)

        },
        closeEdit() {
            this.active = null
            this.createMode = false
            this.viewMode = false;
        },
        async updateUser(item) {
            const transformed = {
                ...item.original,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                password: item.password,
                role: item.role,
            }
            const user = await this.update('users', transformed);
            notifyOk(this.$t('notification.general_information-updated'));
            this.updateIn(this.users, user);
            this.active = user
        },
        async createUser(item) {
            const transformed = {
                ...item,
                role: item.role.value,
            }
            const discount = await this.create('users', transformed);
            this.users.push(discount);
            notifyOk(this.$t('notification.user_saved'));
            this.active = discount
            this.createMode = false
            this.viewMode = false;
            this.viewMode = true;
        },
        async del(user) {
            const {result, archived} = await this.delete('users', user);
            if (result === 'destroyed') {
                notifyOk(this.$t('notification.user_deleted'));
                this.deleteIn(this.users, user);
                this.createMode = false
                this.closeEdit()
            } else if (result === 'archived') {
                notifyOk("Uživatel byl archivován.");
                user.archived = archived;
                this.updateIn(this.users, user);
                this.closeEdit()
            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }
        },
    },
    @cancellableFetch
    async created() {
        await this.fetchAll('settings');
        await this.fetchMany('users');
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    }
};
</script>
<style lang="scss" scoped>
.page{
    overflow: hidden;
    height: 100vh;
}
.wrapper{
    overflow: hidden;
    position: relative;
    padding: 32px 16px 0;
}
.page-title{
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid $black-200;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.discounts-wrapper {
    height: calc(100vh -  121px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}
.create-card, .skeleton{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}
.empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 188px;
    width: 100%;
    border: 1px solid #BABABA;

}
</style>
